import { graphql, Link, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import clx from 'classnames'
import styles from './Header.module.css'
import Logo from '../images/PolyniqueLogo.svg'
import Hamburger from '../images/menu.svg'
import Close from '../images/close.svg'
import SocialSection from './SocialSection'
import Search from './Search'
import BtnIcon from './BtnIcon'
import SearchIcon from '../images/search.svg'

interface MenuItem {
  id: string
  url?: string
  label?: string
}

interface HeaderProps {
  siteTitle: string
  menu: MenuItem[]
  isHome: boolean
  page: number
}

const Header: React.FC<HeaderProps> = ({
  siteTitle = '',
  menu,
  isHome,
  page,
}) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState(false)
  return (
    <>
      <header className={styles.container}>
        <div className={styles.navContainer}>
          <BtnIcon
            type="button"
            aria-label="Open menu"
            className={styles.hamburger}
            onClick={() => setOpen(true)}
          >
            <Hamburger />
          </BtnIcon>
          <Link className={styles.logoLink} to="/" title="Polynique">
            {isHome && (
              <h1 className={styles.textLogo}>
                Polynique{page > 1 && ` - Page ${page}`}
              </h1>
            )}
            <Logo className={styles.logo} />
          </Link>
          <ul className={styles.menuList}>
            {menu.map(({ id, url, label }, index) => (
              <li
                key={id}
                className={styles.menuListItem}
                style={{ animationDelay: `${0.1 * index}s` }}
              >
                {url ? (
                  <Link to={url} title={label}>
                    {label}
                  </Link>
                ) : (
                  `${label}`
                )}
              </li>
            ))}
          </ul>
          <BtnIcon
            className={styles.search}
            onClick={() => setSearch(!search)}
            aria-label="Search"
          >
            <SearchIcon />
          </BtnIcon>
        </div>
      </header>
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
          }
        `}
        render={data => (
          <Search searchIndex={data.siteSearchIndex.index} open={search} />
        )}
      />
      <nav className={clx(styles.menu, { [styles.open]: open })}>
        <div className={styles.menuContainer}>
          <BtnIcon
            aria-label="Close menu"
            className={styles.close}
            onClick={() => setOpen(false)}
          >
            <Close />
          </BtnIcon>
          <ul className={styles.menuList}>
            {menu.map(({ id, url, label }, index) => (
              <li
                key={id}
                className={styles.menuListItem}
                style={{ animationDelay: `${0.1 * index}s` }}
              >
                {url ? (
                  <Link to={url} title={label}>
                    {label}
                  </Link>
                ) : (
                  `${label}`
                )}
              </li>
            ))}
          </ul>
          <SocialSection className={styles.socialMenu} />
        </div>
      </nav>
    </>
  )
}

export default Header
