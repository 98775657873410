import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import clx from 'classnames'
import Header from './Header'
import './layout.css'
import styles from './Layout.module.css'
// import CookieConsent from './CookieConsent'
import SocialSection from './SocialSection'
import Banner from './Banner'

interface LayoutProps {
  children: React.ReactNode
  isHome?: boolean
  page?: number
}

interface StaticQueryResponse {
  site: {
    buildTime: string
    siteMetadata: {
      description: string
    }
  }
  wp: any
  wpMenu: any
}

const Layout: React.FC<LayoutProps> = ({ children, isHome, page = 0 }) => {
  const data = useStaticQuery<StaticQueryResponse>(graphql`
    query SiteTitleQuery {
      site {
        buildTime
        siteMetadata {
          description
        }
      }
      wp {
        generalSettings {
          title
          description
        }
      }
      wpMenu(id: { eq: "dGVybToxNzQ=" }) {
        menuItems {
          nodes {
            id
            url
            label
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        isHome={isHome || false}
        page={page}
        siteTitle={data.wp.generalSettings?.title || `Title`}
        menu={data.wpMenu?.menuItems?.nodes || []}
      />
      <div className={styles.layout}>
        <main className={clx(styles.container, styles.main)}>
          <Banner
            className={clx(styles.banner, 'adsbygoogle')}
            slot="9910180190"
            style={{ width: '970px', height: '90px', display: 'inline-block' }}
          />
          {children}
        </main>
        <footer className={styles.footer}>
          <div className={clx(styles.container)}>
            <div className={styles.network}>
              <p>
                Tools from our network:{' '}
                <a
                  href="https://email-signature-generator.polynique.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email Signature Generator
                </a>{' '}
                |{' '}
                <a
                  href="https://rapidapi.com/domware.apps/api/pdf-generator3/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PDF Generator API
                </a>
              </p>
            </div>
            <div className={styles.copyright}>
              <p className={styles.meta}>
                Polynique © 2020 - {new Date().getFullYear()}, made with ❤️ by a
                developer, for developers.
              </p>
              <p className={clx(styles.meta, styles.menu, styles.update)}>
                Latest update:{' '}
                <time dateTime={data.site?.buildTime}>
                  {new Date(data.site?.buildTime).toLocaleString()}
                </time>
              </p>
            </div>
            <SocialSection className={styles.socials} />
            {/* <p className={styles.meta}>{data.site.siteMetadata.description}</p> */}
            <p className={clx(styles.meta, styles.menu)}>
              All trademarks, service marks and company names are the property
              of their respective owners.
            </p>
            <p className={clx(styles.meta, styles.menu)}>
              <Link to="/about/" title="About Polynique">
                About
              </Link>
              <Link to="/privacy-policy/" title="Privacy Policy">
                Privacy Policy
              </Link>
              <Link to="/cookie-policy/" title="Cookie Policy">
                Cookie Policy
              </Link>
            </p>
          </div>
        </footer>
        {/* <CookieConsent /> */}
      </div>
    </>
  )
}

export default Layout
