import React from 'react'
import clx from 'classnames'
import styles from './BtnIcon.module.css'

const BtnIcon: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...props
}) => {
  return <button className={clx(styles.icon, className)} {...props} />
}

export default BtnIcon
