/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

interface SeoProps {
  description?: string
  lang?: string
  featuredImage?: string
  meta?: { name: string; content: string }[]
  title: string
  canonical?: string
  schema?: string
}

const filterMeta = (
  meta: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >
): boolean => {
  if (meta && meta.name && meta.content) {
    return true
  } else return false
}

const SEO: React.FC<SeoProps> = ({
  description,
  featuredImage,
  lang = 'en',
  meta = [],
  title,
  canonical,
  schema,
}) => {
  const metaDescription = description
  // const defaultTitle = site.siteMetadata?.title
  return (
    <StaticQuery
      query={graphql`
        {
          wp {
            seo {
              social {
                twitter {
                  username
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          link={
            canonical
              ? [
                  {
                    rel: 'canonical',
                    href: `https://www.polynique.com${canonical}`,
                  },
                ]
              : undefined
          }
          // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: featuredImage ? `summary_large_image` : `summary`,
            },
            {
              name: `twitter:site`,
              content: data.wp.seo.social?.twitter?.username
                ? `@${data.wp.seo.social?.twitter?.username}`
                : '',
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `twitter:image`,
              content: featuredImage
                ? `https://www.polynique.com${featuredImage}`
                : undefined,
            },
          ]
            .concat(meta)
            .filter(filterMeta)}
        >
          {schema && <script type="application/ld+json">{schema}</script>}
        </Helmet>
      )}
    />
  )
}

export default SEO
