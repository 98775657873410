import React, { Component } from 'react'
import { Index } from 'elasticlunr'
import { Link } from 'gatsby'
import clx from 'classnames'
import styles from './Search.module.css'

export const SearchResults = ({ searchIndex, query }) => {
  const index = Index.load(searchIndex)
  const results = index
    .search(query, {})
    // Map over each ID and return the full document
    .map(({ ref }) => index.documentStore.getDoc(ref))

  return (
    <ul>
      {results.map(page => (
        <li key={page.id}>
          <Link to={page.uri}>{page.title}</Link>
          {page.tags.length && (
            <span className={styles.tags}>
              {page.tags.map((tag: string) => (
                <span className={styles.tag}># {tag}</span>
              ))}
            </span>
          )}
        </li>
      ))}
    </ul>
  )
}

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    const { open } = this.props
    const { results, query } = this.state
    return (
      <div className={clx(styles.searchContainer, { [styles.open]: open })}>
        <input
          className={styles.search}
          type="search"
          value={query}
          onChange={this.search}
        />
        {!!(results && results.length && open) && (
          <ul className={styles.results}>
            {results.map(page => (
              <li key={page.id}>
                <Link to={page.uri}>{page.title}</Link>
                {page.tags.length && (
                  <span className={styles.tags}>
                    {page.tags.map((tag: string) => (
                      <span className={styles.tag}># {tag}</span>
                    ))}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
