import React from 'react'
import clx from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { FacebookIcon, PinterestIcon, TwitterIcon } from 'react-share'
import { SocialIcon } from 'react-social-icons'
import styles from './SocialSection.module.css'

const getSocials = (
  data: any
): {
  facebook?: string
  twitter?: string
  pinterest?: string
  instagram?: string
} => {
  const { social } = data.wp.seo
  const {
    facebook: { url: facebook },
    twitter: { username: twitter },
    pinterest: { url: pinterest },
    instagram: { url: instagram },
  } = social
  return { facebook, twitter, pinterest, instagram }
}

interface SocialSectionProps {
  className?: string
}

const SocialSection: React.FC<SocialSectionProps> = ({ className = '' }) => {
  const data = useStaticQuery<any>(graphql`
    query SocialQuery {
      wp {
        seo {
          social {
            facebook {
              url
            }
            instagram {
              url
            }
            pinterest {
              url
            }
            twitter {
              username
            }
          }
        }
      }
    }
  `)
  const { facebook, pinterest, twitter, instagram } = getSocials(data)
  return (
    <div className={clx(className, styles.socials)}>
      {facebook && (
        <a
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          title="Polynique Facebook"
        >
          <FacebookIcon />
        </a>
      )}
      {pinterest && (
        <a
          href={pinterest}
          target="_blank"
          rel="noopener noreferrer"
          title="Polynique Pinterest"
        >
          <PinterestIcon />
        </a>
      )}
      {twitter && (
        <a
          href={`https://twitter.com/${twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          title="Polynique Twitter"
        >
          <TwitterIcon />
        </a>
      )}
      {instagram && (
        <SocialIcon
          url={instagram}
          target="_blank"
          className={clx(styles.social, styles.instagram)}
          rel="noopener noreferrer"
          title="Polynique Instagram"
        />
      )}
      <SocialIcon
        url="https://github.com/polynique"
        className={clx(styles.github, styles.social)}
        target="_blank"
        rel="noopener noreferrer"
        title="Polynique Github"
      />
    </div>
  )
}

export default SocialSection
